import * as React from "react"
import { Link, graphql } from "gatsby"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../styles/main.scss"
import { Helmet } from "react-helmet"

export const VideosPageTemplate = ({ videos }) => {
  function getId(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
    const match = url.match(regExp)

    return match && match[2].length === 11 ? match[2] : null
  }

  const videosUI = videos.map(video => {
    const videoID = getId(video.link)

    return (
      <div className="tileTwo">
        <div class="video-container">
          <iframe
            height="auto"
            src={`https://www.youtube.com/embed/${videoID}`}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    )
  })
  return (
    <div className="container sectionThree">
      <div className="content">
        {videosUI}
        <a
          target="_blank"
          href="https://dedicated-founder-2746.ck.page/02a94ba0d8"
        >
          <div class="pointer">
            <p>Subscribe To Newsletter</p>
          </div>
        </a>
        <a target="_blank" href="https://michelpasvar.bigcartel.com/">
          <div class="pointer">
            <p>Shop Merch</p>
          </div>
        </a>
      </div>
    </div>
  )
}

const VideosPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <VideosPageTemplate videos={frontmatter.videos} />
    </Layout>
  )
}

VideosPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default VideosPage

export const pageQuery = graphql`
  query VideosPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "videos-page" } }) {
      frontmatter {
        videos {
          link
        }
      }
    }
  }
`
